import { JSX }	from "preact";

import * as fbModel		from "@geotoura/shared/fbModel";

import * as actions		from "@geotoura/fb/actions";
import { RadioButton }	from "@geotoura/fb/components/RadioButton";

export type DecisionProps	= Readonly<{
	screen:	fbModel.ScreenOfDecision,
	answer:	fbModel.AnswerOfDecision,
}>;

export const Decision = ({ screen, answer }:DecisionProps):JSX.Element => {
	const setRadio = (wasChecked:boolean, valueRadio:string, radioItemId:fbModel.RadioItemId):void => {
		actions.doRefresh(
			actions.answerContent(screen.id).cast<fbModel.ADecision>().mod(it =>
				({
					...it,
					radioItemId:	wasChecked? null : radioItemId,
					valueRadio:		wasChecked? null : valueRadio,
					mailRadioValue:	!wasChecked && valueRadio !== ""
									?	screen.content.radioGroup.items.find(it => it.valueRadio === valueRadio)?.label ?? ""
									:	"",
				})
			)
		);
	};

	return (
		<div class="Decision Screen">
			<div class="heading">{screen.content.radioGroup.title}</div>
			<div class={`RadioGroup ${screen.content.radioGroup.layout}`}>
				{
					screen.content.radioGroup.items.map((item) =>
						<RadioButton
							key={item.radioItemId}
							checked={answer.content.valueRadio}
							item={item}
							action={setRadio}
						/>
					)
				}
			</div>
		</div>
	);
};
