import { JSX, ComponentChildren }	from "preact";
import { useId }					from "preact/hooks";

export type CheckboxProps<T>	= Readonly<{
	index:		number,
	checked:	boolean,
	action:		(it:T, index:number, checked:boolean)=>void,
	value:		T,
	children:	ComponentChildren,
}>;

export const Checkbox = <T,>({ index, checked, action, value, children }:CheckboxProps<T>):JSX.Element => {
	const uid	= useId();

	return (
		<div class="Checkbox">
			<input
				type="checkbox"
				onClick={() => action(value, index, checked)}
				class="sr-only"
				id={uid}
				checked={ checked }
			/>
			<label class={ "Checkbox-label " + (checked ? "checked" : "unchecked") } for={uid}>
				{children}
			</label>
		</div>
	);
};
