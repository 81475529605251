import { Fn, Fn2 }	from "ts-base/fn";

import { Euro, EuroCent, Fraction, Percent }	from "@geotoura/shared/money";

export type NumberFormat	= Readonly<{
	euroInteger:		Fn<Euro, string>,
	euroAndCent:		Fn<Euro, string>,
	centAsEuro:			Fn<EuroCent, string>,
	centAsEuroRange:	Fn2<EuroCent, EuroCent, string>,
	percentInteger:		Fn<Percent, string>,
}>;

export namespace NumberFormat {
	export const simple	= (locale:string):NumberFormat	=> {
		const euro	= (digits:number):Intl.NumberFormat	=>
			new Intl.NumberFormat(
				locale,
				{
					style:					"currency",
					currency:				"EUR",
					minimumFractionDigits:	digits,
					maximumFractionDigits:	digits,
					useGrouping:			true,
				}
			);

		const percent	= (digits:number):Intl.NumberFormat	=>
			new Intl.NumberFormat(
				locale,
				{
					style:					"percent",
					minimumFractionDigits:	digits,
					maximumFractionDigits:	digits,
					useGrouping:			false,
				}
			);

		const euro0		= euro(0);
		const euro2		= euro(2);
		const percent0	= percent(0);

		return {
			euroInteger:		(it:Euro):string					=> euro0.format(it),
			euroAndCent:		(it:Euro):string					=> euro2.format(it),
			centAsEuro:			(it:EuroCent):string				=> euro0.format(Euro.fromEuroCent(it)),
			centAsEuroRange:	(min:EuroCent, max:EuroCent):string	=> euro0.formatRange(Euro.fromEuroCent(min), Euro.fromEuroCent(max)),
			percentInteger:		(it:Percent):string					=> percent0.format(Fraction.fromPercent(it)),
		};
	};
}
