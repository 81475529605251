import { JSX }	from "preact";

import * as fbModel		from "@geotoura/shared/fbModel";

import * as actions		from "@geotoura/fb/actions";
import { RadioButton }	from "@geotoura/fb/components/RadioButton";

export type RadiostartProps	= Readonly<{
	screen:	fbModel.ScreenOfRadiostart,
	answer:	fbModel.AnswerOfRadiostart,
}>;

export const Radiostart = ({ screen, answer }:RadiostartProps):JSX.Element => {
	const setRadio = (wasChecked:boolean, valueRadio:string, radioItemId:fbModel.RadioItemId):void => {
		actions.doRefresh(
			actions.answerContent(screen.id).cast<fbModel.ARadiostart>().mod(it =>
				({
					...it,
					radioItemId:	wasChecked ? null : radioItemId,
					valueRadio:		wasChecked ? null : valueRadio,
					mailRadioValue:	!wasChecked && valueRadio !== ""
									?	screen.content.radioGroup.items.find(it => it.valueRadio === valueRadio)?.label ?? ""
									:	"",
				})
			)
		);
		if (valueRadio !== "") {
			// TODO fb this is wrong, we shouldn't call two actions in one event listener
			actions.goNext();
		}
	};

	return	(
		<div class="Radiostart Screen">
			<div class="fb-paragraph">{screen.content.intro}</div>
			<div class="heading">{screen.content.radioGroup.title}</div>
			<div class={`RadioGroup ${screen.content.radioGroup.layout}`}>
				{
					screen.content.radioGroup.items.map((item) =>
						<RadioButton
							key={item.radioItemId}
							checked={answer.content.valueRadio}
							item={item}
							action={setRadio}
						/>
					)
				}
			</div>
		</div>
	);
};
