import { JSX }		from "preact";
import { useId }	from "preact/hooks";

import * as fbModel from "@geotoura/shared/fbModel";

export type RadioButtonProps	= Readonly<{
	item:		fbModel.RadioItem,
	checked:	string | null,
	action:		(wasChecked:boolean, it:string, at:fbModel.RadioItemId) => void,
}>;

export const RadioButton = ({ item, checked, action }:RadioButtonProps):JSX.Element => {
	const uid			= useId();

	const toggleButton	= ():void =>
		action(
			item.valueRadio === checked,
			item.valueRadio,
			item.radioItemId
		);

	return (
		<div class="RadioButton">
			<input
				type="radio"
				onClick={toggleButton}
				class="sr-only"
				id={uid}
				value={ item.valueRadio }
				checked={ item.valueRadio === checked }
			/>
			<label class={ "RadioButton-label " + (item.valueRadio === checked ? "checked" : "unchecked") } for={uid}>
				{
					item.icon !== null &&
					<div class="RadioButton-icon" style={{ backgroundImage: `url("${item.icon}")` }}></div>
				}
				<div class="RadioButton-text">{item.label}</div>
			</label>
		</div>
	);
};
