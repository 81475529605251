import { JSX }	from "preact";

import { useMessages }	from "@geotoura/fb/useMessages";
import * as actions		from "@geotoura/fb/actions";

export const CloseButton = ():JSX.Element => {
	const msg = useMessages();

	return (
		<button onClick={() => actions.close()} type="button" class="CloseButton">
			<span class="sr-only">{msg.closeButton.sr}</span>
		</button>
	);
};
