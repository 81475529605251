import { JSX }	from "preact";

import * as fbModel		from "@geotoura/shared/fbModel";

import * as actions		from "@geotoura/fb/actions";
import { Counter }		from "@geotoura/fb/components/Counter";

export type CounterlistProps	= Readonly<{
	screen:	fbModel.ScreenOfCounterList,
	answer:	fbModel.AnswerOfCounterList,
}>;

export const Counterlist = ({ screen, answer }:CounterlistProps):JSX.Element => {
	const setCounter = (index:number, val:number):void => {
		const newAnswer:fbModel.ACountItem = {
			... answer.content.counterValues[index],
			keyCounter:		answer.content.counterValues[index].keyCounter,
			counterValue:	val,
			mailKey:		screen.content.list[index].mailKey,
		};

		actions.doRefresh(
			actions.answerContent(screen.id).cast<fbModel.ACounterList>().atKey("counterValues").atIndexRo(index).set(newAnswer)
		);
	};

	return (
		<div class="Counterlist Screen">
			{
				screen.content.list.map((it:fbModel.CountItem, index:number) =>
					<Counter
						counterValue={answer.content.counterValues[index].counterValue}
						countItem={it}
						action={(val) => setCounter(index, val)}
					/>
				)
			}
		</div>
	);
};
