import { Fn }			from "ts-base/fn";
import { Endo }			from "ts-base/endo";
import { Arrays }		from "ts-base/arrays";
import { Zoomer }		from "ts-base/zoomer";

import { Logger }	from "@geotoura/shared/logger";
import * as i18n	from "@geotoura/shared/i18n";
import * as fbModel from "@geotoura/shared/fbModel";

import * as connect		from "@geotoura/common/util/connect";
import * as Server		from "@geotoura/common/Server";
import * as tracking	from "@geotoura/common/privacy/tracking";

import * as util	from "@geotoura/fb/util";
import {
	Model,
	QuestionnaireData,
	initQuestionnaire,
	currentFormValidity,
	FormValidity,
	currentScreenMode,
	DisplayMode,
}	from "@geotoura/fb/model";

const logger	= Logger.create("fb-actions");

//-----------------------------------------------------------------------------
//## refresh

let handle:connect.Upd<Model>|null	= null;

export const setRefresh	= (it:connect.Upd<Model>):void => { handle = it; };

const refresh:connect.Upd<Model>	= (change:Endo<Model>):void => {
	if (handle === null)	throw new Error("refresh was called before setRefresh");
	handle(change);
};

// exposed because some components call this directly
export const doRefresh	= refresh;

//-----------------------------------------------------------------------------

// initial action, called right after startup
export const boot = (questionaireId:fbModel.QuestionnaireId, lang:i18n.LanguageCode):void =>
	void getQuestionnaire(questionaireId, lang);

export const getQuestionnaire	= async (questionaireId:fbModel.QuestionnaireId, lang:i18n.LanguageCode):Promise<void> => {
	try {
		const questionnaire	= await Server.getQuestionnaire({ lang: lang, id: questionaireId });

		refresh(
			modelZoomer.atKey("questionnaireData").set(initQuestionnaire(questionnaire))
		);
	}
	catch (e) {
		logger.error("Could not load questionnaire", e);
	}
};

export const postQuestionnaire	= (languageCode:i18n.LanguageCode):void =>
	refresh((model) => {
		if (model.questionnaireData === null)	return model;
		const data	= model.questionnaireData;

		const formIsValid	= FormValidity.fullyValid(currentFormValidity(model));
		if (!formIsValid) {
			// Stay on screen!
			return modelZoomer.atKey("formDirty").set(true)(model);
		}

		const result:fbModel.Result = {
			answers:					data.answers,
			questionnaireId:			model.questionnaireId,
			questionnaireName:			model.questionnaireName,
			questionnaireDescription:	data.questionnaire.description ?? "",
			territoryId:				model.pageInfo.type === "territory"
											? model.pageInfo.territoryId
											: null,
			regionId:					model.pageInfo.type === "region"
											? model.pageInfo.regionId
											: null,
			routeId:					model.pageInfo.type === "region" || model.pageInfo.type === "route"
											? model.pageInfo.routeId
											: null,
		};

		const request	= {
			lang:	languageCode,
			result:	result,
		};

		void actuallyPostQuestionnaire(request);

		return Fn.andThen(
			dataZoomer.atKey("currentScreen").mod(it => it+1),
			modelZoomer.atKey("questionnaireData").execute(updateOverlay)
		)(model);
	});

const actuallyPostQuestionnaire	= async (request:fbModel.AnswersRequest):Promise<void> => {
	try {
		const json	= await Server.postQuestionnaire(request);

		tracking.gaSendEvent({
			action:		"questionnaire-submit",
			category:	"questionnaire",
			label:		`questionnaire-id: ${request.result.questionnaireId}`,
			value:		10,
		});
		refresh(
			modelZoomer.atKey("serverResponse").set(json.return)
		);
	}
	catch (e) {
		logger.error("postQuestionnaire Error", e);
		refresh(
			modelZoomer.atKey("serverResponse").set("error")
		);
	}
};

export const close	= ():void =>
	refresh(
		Fn.andThen3(
			dataZoomer.atKey("currentScreen").set(0),
			displayModeZoomer.set("integrated"),
			modelZoomer.atKey("questionnaireData").execute(updateOverlay)
		)
	);

export const setFullscreen	= ():void => {
	tracking.gaSendEvent({
		action:		"questionnaire-open",
		category:	"questionnaire",
		label:		"questionnaire-pagebutton",
		value:		1,
	});

	refresh(
		Fn.andThen(
			displayModeZoomer.set("fullscreen"),
			modelZoomer.atKey("questionnaireData").execute(updateOverlay)
		)
	);
};

export const goNext	= ():void =>
	refresh(
		Fn.andThen(
			dataZoomer.mod((data) => {
				const newData	= evaluateAndSetShow(data);
				// NOTE screens has 1 more elements than answers, so navigating behind the last answer is actually ok
				const newScreen	= Arrays.findFirstIndexOrNull(newData.answers, newData.currentScreen+1)(it => it.show) ?? newData.answers.length;

				tracking.gaSendEvent({
					action:		"questionnaire-gonext",
					category:	"questionnaire",
					label:		`questionnaire-screen-nr: ${newScreen}`,
					value:		1,
				});

				return { ...newData, currentScreen: newScreen };
			}),
			modelZoomer.atKey("questionnaireData").execute(updateOverlay)
		)
	);

export const goBack	= ():void =>
	refresh(
		Fn.andThen(
			dataZoomer.mod((data) => {
				const newData	= evaluateAndSetShow(data);
				const newScreen	= Arrays.findLastIndexOrNull(newData.answers, newData.currentScreen-1)(it => it.show) ?? 0;
				return { ...newData, currentScreen: newScreen };
			}),
			modelZoomer.atKey("questionnaireData").execute(updateOverlay)
		)
	);

const evaluateAndSetShow = (data:QuestionnaireData):QuestionnaireData => ({
	...data,
	answers:	updateAnswers(data.questionnaire.screens, [], data.answers),
});

const updateAnswers = (screens:ReadonlyArray<fbModel.Screen>, done:ReadonlyArray<fbModel.Answer>, todo:ReadonlyArray<fbModel.Answer>):ReadonlyArray<fbModel.Answer> => {
	if (todo.length === 0)	return done;

	// the show flag of earlier answers can influence the show flag of later answers, therefore we have to pass them to updateAnswer here
	const updated	= updateAnswer(screens, done, todo[0]);
	return updateAnswers(
		screens,
		Arrays.append(done)(updated),
		Arrays.tail(todo)
	);
};

const updateAnswer	= (screens:ReadonlyArray<fbModel.Screen>, done:ReadonlyArray<fbModel.Answer>, answer:fbModel.Answer):fbModel.Answer => {
	const screen	= screens.find(it => it.questionnaireScreenId === answer.questionnaireScreenId);
	if (screen === undefined) {
		logger.debug("ignored malformed condition - no screen found for the questionnaire screen", answer.questionnaireScreenId);
	}

	const show		=
		screen === undefined ||
		screen.conditions.every((condition) => {
			const source	= done.find(it => it.questionnaireScreenId === condition.sourceQuestionnaireScreenId);
			if (source === undefined) {
				logger.debug("ignored malformed condition - source does not exist at condition_id or is defined after the current answer", condition.conditionId);
				return true;
			}

			if (!source.show) {
				logger.debug("ignored hidden condition", condition.conditionId);
				return true;
			}

			const value	= relatedRadioItemId(source);

			return (value === condition.expectedRadioItemId) === condition.mustBe;
		});

	return {
		...answer,
		show,
	};
};

const relatedRadioItemId = (related:fbModel.Answer) => {
	switch (related.stId) {
		case "RADIOSTART":	return related.content.radioItemId;
		case "FLIGHT":		return related.content.radioItemId;
		case "HOTEL":		return related.content.radioItemId;
		case "DECISION":	return related.content.radioItemId;
		default:			return null;
	}
};

// this has to be called whenever currentIndex has been changed
const updateOverlay	= (data:QuestionnaireData|null):void	=> {
	const screenMode	= data !== null ? currentScreenMode(data) : null;
	util.setOverlay(
		screenMode !== null &&
		screenMode !== "first"
	);
};

//-----------------------------------------------------------------------------

const modelZoomer	= Zoomer.on<Model>();

const dataZoomer:Zoomer<Model, QuestionnaireData>	=
	modelZoomer.atKey("questionnaireData").notNull();

export const answerContent = (screenId:fbModel.ScreenId): Zoomer<Model, fbModel.AnswerType> =>
	dataZoomer
	.atKey("answers")
	.whereRo((it:fbModel.Answer) => it.screenId === screenId)
	.atKey("content");

const displayModeZoomer:Zoomer<Model, DisplayMode>	=
	modelZoomer.atKey("displayMode");
