import { Branded }	from "ts-base/branded";
import { Numbers }	from "ts-base/numbers";
import { Group }	from "ts-base/group";

//-----------------------------------------------------------------------------

export namespace Currency {
	export const Euro:Currency	= {
		code:	"EUR",
		num:	"978",
	};
}

// valid currency per iso_4217 standard
export type Currency	= Readonly<{
	code:	string,
	num:	string,
}>;

//-----------------------------------------------------------------------------

/*
0 eurocent		= 0 euro
100 eurocent	= 1 euro
*/

export type Euro	= Branded<number, "Euro">;

export namespace Euro {
	export const brand	= Branded.brand<Euro>;

	export const zero	= brand(0);

	export const fromEuroCent	= (it:EuroCent):Euro	=> brand(it / 100);

	export const fromNumber	= (n:number):Euro|null	=>
		n >= 0 && Number.isFinite(n) ? brand(n !== 0 ? n : 0) : null;

	export const roundTo	= (value:Euro, precision:number):Euro	=>
		brand(Numbers.roundTo(value, precision));

	export const multiply	= (value:Euro, factor:number):Euro	=>
		brand(value * factor);

	export const divide	= (value:Euro, factor:number):Euro	=>
		brand(value / factor);

	export const add	= (a:Euro, b:Euro):Euro	=>
		brand(a + b);

	export const subtract	= (a:Euro, b:Euro):Euro	=>
		brand(a - b);

	export const negate		= (it:Euro):Euro	=>
		// eslint-disable-next-line @typescript-eslint/no-unsafe-unary-minus
		brand(-it);

	export const sum:Group<Euro>	= {
		empty:			zero,
		concat:			add,
		inverse:		negate,
		concatInverse:	subtract,
	};
}

//-----------------------------------------------------------------------------

/*
0 eurocent		= 0 euro
100 eurocent	= 1 euro
*/

export type EuroCent	= Branded<number, "EuroCent">;

export namespace EuroCent {
	export const brand	= Branded.brand<EuroCent>;

	export const zero	= brand(0);

	export const fromEuro	= (it:Euro):EuroCent	=> brand(it * 100);

	export const fromNumber	= (n:number):EuroCent|null	=>
		n >= 0 && Number.isFinite(n) ? brand(n !== 0 ? n : 0) : null;

	export const roundTo	= (value:EuroCent, precision:number):EuroCent	=>
		brand(Numbers.roundTo(value, precision));

	export const multiply	= (value:EuroCent, factor:number):EuroCent	=>
		brand(value * factor);

	export const divide	= (value:EuroCent, factor:number):EuroCent	=>
		brand(value / factor);

	export const add	= (a:EuroCent, b:EuroCent):EuroCent	=>
		brand(a + b);

	export const subtract	= (a:EuroCent, b:EuroCent):EuroCent	=>
		brand(a - b);

	export const negate		= (it:EuroCent):EuroCent	=>
		// eslint-disable-next-line @typescript-eslint/no-unsafe-unary-minus
		brand(-it);

	export const sum:Group<EuroCent>	= {
		empty:			zero,
		concat:			add,
		inverse:		negate,
		concatInverse:	subtract,
	};
}

//-----------------------------------------------------------------------------

/*
0 percent	= 0 fraction
100 percent	= 1 fraction
*/

export type Percent	= Branded<number, "Percent">;

export namespace Percent {
	export const brand	= Branded.brand<Percent>;

	export const zero	= brand(0);

	export const fromFraction	= (it:Fraction):Percent	=> brand(it * 100);

	export const fromNumber	= (n:number):Percent|null	=>
		n >= 0 && Number.isFinite(n) ? brand(n !== 0 ? n : 0) : null;

	export const roundTo	= (value:Percent, precision:number):Percent	=>
		brand(Numbers.roundTo(value, precision));

	export const add	= (a:Percent, b:Percent):Percent	=>
		brand(a + b);

	export const subtract	= (a:Percent, b:Percent):Percent	=>
		brand(a - b);

	export const negate		= (it:Percent):Percent	=>
		// eslint-disable-next-line @typescript-eslint/no-unsafe-unary-minus
		brand(-it);

	export const sum:Group<Percent>	= {
		empty:			zero,
		concat:			add,
		inverse:		negate,
		concatInverse:	subtract,
	};
}

//-----------------------------------------------------------------------------

/*
0 percent	= 0 fraction
100 percent	= 1 fraction
*/

export type Fraction	= Branded<number, "Fraction">;

export namespace Fraction {
	export const brand	= Branded.brand<Fraction>;

	export const zero	= brand(0);

	export const fromPercent	= (it:Percent):Fraction	=> brand(it / 100);

	export const fromNumber	= (n:number):Fraction|null	=>
		n >= 0 && Number.isFinite(n) ? brand(n !== 0 ? n : 0) : null;

	export const roundTo	= (value:Fraction, precision:number):Fraction	=>
		brand(Numbers.roundTo(value, precision));

	export const add	= (a:Fraction, b:Fraction):Fraction	=>
		brand(a + b);

	export const subtract	= (a:Fraction, b:Fraction):Fraction	=>
		brand(a - b);

	export const negate		= (it:Fraction):Fraction	=>
		// eslint-disable-next-line @typescript-eslint/no-unsafe-unary-minus
		brand(-it);

	export const sum:Group<Fraction>	= {
		empty:			zero,
		concat:			add,
		inverse:		negate,
		concatInverse:	subtract,
	};
}
