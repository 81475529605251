import { JSX }	from "preact";

import * as fbModel	from "@geotoura/shared/fbModel";

export type CounterProps	= Readonly<{
	countItem:		fbModel.CountItem,
	counterValue:	number,
	action:			(it:number)=>void,
}>;

export const Counter = ({ countItem, counterValue, action }:CounterProps):JSX.Element => {
	const increaseCounter = ():void => {
		if (counterValue < countItem.max) action(counterValue + 1);
	};

	const decreaseCounter = ():void => {
		if (counterValue > countItem.min) action(counterValue - 1);
	};

	return (
		<div class="Counter center-column">
			<div class="Counter-title heading">{countItem.title}</div>
			<div class="Counter-content">
				<div class="Counter-content-col icon">
					<img src={countItem.icon} alt="" class="Counter-icon"/>
				</div>
				<div class="Counter-content-col text">
					<div class="Counter-value">{counterValue}</div>
					<div class="Counter-subtitle">{countItem.subTitle}</div>
					<div class="Counter-buttons">
						<button onClick={decreaseCounter} type="button" class="Counter-button"><span class="Counter-button-inner">−</span></button>
						<button onClick={increaseCounter} type="button" class="Counter-button"><span class="Counter-button-inner">+</span></button>
					</div>
				</div>
			</div>
		</div>
	);
};
