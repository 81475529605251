import { JSX }	from "preact";

import { classes }	from "ts-base/web/dom";

import * as i18n	from "@geotoura/shared/i18n";
import * as fbModel from "@geotoura/shared/fbModel";

import { ProvideLanguageCode }	from "@geotoura/common/util/useLanguageCode";

import * as actions			from "@geotoura/fb/actions";
import { ProvideMessages }	from "@geotoura/fb/useMessages";
import { Messages }			from "@geotoura/fb/locale";
import {
	Model,
	currentFormValidity,
	FormValidity,
	currentScreenMode,
	progress,
}	from "@geotoura/fb/model";

import { ScreenDisplay }		from "@geotoura/fb/components/ScreenDisplay";
import { Nav }					from "@geotoura/fb/components/Nav";
import { CloseButton }			from "@geotoura/fb/components/CloseButton";
import { ProgressIndicator	}	from "@geotoura/fb/components/ProgressIndicator";

export type AppProps	= Readonly<{
	// this only exists to pass it to the server in the post of the result
	languageCode:	i18n.LanguageCode,
	messages:		Messages,
	model:			Model,
}>;

export const App = ({ languageCode, messages, model }:AppProps):JSX.Element => {
	// Get the days entered by the user on the Date screen for the Budget screen
	// to make sense the Date screen must be used only once in the questionnaire and come before the Budget screen
	const getTraveldays = (answers:ReadonlyArray<fbModel.Answer>):number => {
		const dateAnswer	= answers.find(fbModel.Answer.isOf("DATE"));
		return dateAnswer !== undefined ? dateAnswer.content.counter1.counterValue : 14;
	};

	const formValidity	= currentFormValidity(model);
	const formIsValid	= FormValidity.fullyValid(formValidity);

	const screenMode	= model.questionnaireData !== null ? currentScreenMode(model.questionnaireData) : "first";

	return (
		<ProvideLanguageCode value={languageCode}>
		<ProvideMessages value={messages}>
			<div class={classes(
				"App",
				screenMode === "first" && model.displayMode === "integrated" ? "integrated" : "fullscreen",
				model.questionnaireData !== null	? "visible"		: "hidden"
			)}>
				{
					model.questionnaireData === null
					?	<div class="App-inner App-loading">loading</div>
					:	<div class="App-inner App-loaded">
							{
								!(screenMode === "first" && model.displayMode === "integrated" ) && screenMode !== "thanks" &&
								<div class="App-header">
									<ProgressIndicator progress={progress(model.questionnaireData)}/>
									<img src="/img/fb/icons/logo-tourdream.svg" class="Logo"/>
									<CloseButton/>
								</div>
							}
							<ScreenDisplay
								screen={model.questionnaireData.questionnaire.screens[model.questionnaireData.currentScreen]}
								answer={model.questionnaireData.answers[model.questionnaireData.currentScreen]}
								traveldays={getTraveldays(model.questionnaireData.answers)}
								formValidity={formValidity}
								serverResponse={model.serverResponse}
							/>
							<Nav
								languageCode={languageCode}
								screenMode={screenMode}
								formIsValid={formIsValid}
							/>
						</div>
				}
				<div class="App-starter-button">
					<button class="js-survey" onClick={() => actions.setFullscreen()}></button>
				</div>
			</div>
		</ProvideMessages>
		</ProvideLanguageCode>
	);
};
