import { JSX, ComponentChildren }	from "preact";

import { useMessages }	from "@geotoura/fb/useMessages";

export type ModalProps	= Readonly<{
	open:		boolean,
	action:		() => void,
	children:	ComponentChildren,
}>;

export const Modal = ({ open, action, children }:ModalProps):JSX.Element => {
	const msg = useMessages();

	return	(
		<div class={"modal " + (open ? "visible" : "closed")}>
			<div class="modal-inner">
				<button onClick={action} class="modal-close CloseButton"><span class="sr-only">{msg.modal.close}</span></button>
				<div class="modal-content">
					{children}
				</div>
				<div class="modal-nav">
					<button onClick={action} class="modal-button-save">{msg.modal.submit}</button>
				</div>
			</div>
		</div>
	);
};
