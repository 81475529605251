import { Dates }	from "ts-base/dates";

import * as time	from "@v3/data/time";

export const setOverlay = (enabled:boolean):void => {
	if (enabled) {
		document.body.classList.add("fb-fullheight");
		document.documentElement.style.overflow	= "hidden";
		document.documentElement.style.height	= "100%";
	}
	else {
		document.body.classList.remove("fb-fullheight");
		document.documentElement.style.overflow	= "auto";
		document.documentElement.style.height	= "auto";
	}
};

// TODO i18n unify date handling
// TODO i18n this is using the wrong time zone
export const addDays = (days:number):time.IsoDate => {
	// equivalent to IsoDate.brand(Dates.utc.future({ days }).toISOString().slice(0, 10));
	const date		= Dates.utc.future({ days });
	const timestamp	= time.IsoTimestampF.fromDate(date);
	const dateTime	= time.IsoTimestampF.getIsoDate(timestamp);
	return dateTime;
};
