import { JSX }				from "preact";
import { useState, useId }	from "preact/hooks";

import * as searchText	from "@geotoura/common/util/searchText";

export type AutocompleteProps	= Readonly<{
	action:		(it:string)=>void,
	label:		string,
	value:		string,
	valueList:	ReadonlyArray<string>,
}>;

export const Autocomplete = ({ action, label, value, valueList }:AutocompleteProps):JSX.Element => {
	const uid											= useId();
	const [ isOpen,				setOpen ]				= useState(false);
	const [ selectedSuggestion,	setSelectedSuggestion ]	= useState(0);

	const currentSuggestions	=
		valueList.filter((candidate) =>
			searchText.includes(candidate, value)
		);

	const handleInputEvent = (ev:JSX.TargetedInputEvent<HTMLInputElement>):void => {
		const target	= ev.currentTarget;
		action(target.value);
		setOpen(target.value.length >= 1);
	};

	const handleBlurEvent = ():void => {
		window.setTimeout(() => {
			setOpen(false);
			setSelectedSuggestion(0);
		}, 300);
	};

	const handleClick = (text:string):void => {
		action(text);
	};

	const clearInput = ():void => {
		action("");
	};

	const handleKeypress = (ev:KeyboardEvent):void => {
		if (currentSuggestions.length === 0)	return;

		// TODO fb keyCode is deprecated
		// @see packages/up/src/ts/gui/Search.tsx about how to do this correctly
		switch (ev.keyCode) {
			case 13: {
				action(currentSuggestions[selectedSuggestion]);
				setOpen(false);
				setSelectedSuggestion(0);
				break;
			}
			case 40: {
				const newSelection = selectedSuggestion + 1;
				setSelectedSuggestion(newSelection >= currentSuggestions.length ? 0 : newSelection);
				break;
			}
			case 38: {
				const newSelection = selectedSuggestion - 1;
				setSelectedSuggestion(newSelection < 0 ? (currentSuggestions.length-1) : newSelection);
				break;
			}
		}
	};
	return (
		<div class="Autocomplete flex-r">
			<label for={uid} class="fb-label">{label}</label>
			<div class="Autocomplete-input-wrapper">
				<input
					value={value}
					type="text"
					id={uid}
					class="fb-input"
					autocomplete="off"
					onBlur={handleBlurEvent}
					onInput={handleInputEvent}
					onKeyDown={handleKeypress}
				/>
				{
					value.length > 0 &&
					<button onClick={clearInput} class="Autocomplete-button Autocomplete-clear">
						<span class="fa-light fa-close"></span>
						<span class="sr-only">Clear Field</span>
					</button>
				}
				{
					value.length === 0 &&
					<div class="Autocomplete-button Autocomplete-search">
						<span class="fa-light fa-search"></span>
					</div>
				}
				<div class={"Autocomplete-results" + (isOpen ? " open" : " closed")}>
					{
						currentSuggestions.map((text, i) =>
							<div
								onClick={() => handleClick(text)}
								class={"Autocomplete-result" + (i === selectedSuggestion ? " active" : "")}>
									{text}
							</div>
						)
					}
				</div>
			</div>
		</div>
	);
};
