/* eslint-disable @stylistic/quote-props */

export const matches	= (candidate:string, search:string):boolean =>
	normalize(candidate) === normalize(search);

export const includes	= (candidate:string, search:string):boolean =>
	normalize(candidate).includes(normalize(search));

// NOTE this is public for testing only
// IDEA how about throwing away everthing non-ascii after normalizing/digraphing/lowercasing?
export const normalize = (term:string):string =>
	digraph(
		term
		// decompose diacritica into a separate character
		// NOTE what's the difference between NFD and NFKD?
		.normalize("NFD")
		// remove diacritica
		.replace(/\p{Diacritic}/gu, "")
		// fails with some turkish letters
		.toLowerCase()
	);

// incomplete
const digraphMap = {
	"ß": "ss",
	"æ": "ae",
	"œ": "oe",
	// oe is correct, in practice o is used, too
	"ø": "oe",
};

const digraph	= (term:string):string	=>
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore this is ok, the || catches the bad indices
	[ ...term ].map((c) => digraphMap[c] ?? c).join("");
