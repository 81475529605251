import { JSX }	from "preact";

import * as fbModel		from "@geotoura/shared/fbModel";

import * as actions		from "@geotoura/fb/actions";
import { Counter }		from "@geotoura/fb/components/Counter";
import { RadioButton }	from "@geotoura/fb/components/RadioButton";

export type HotelProps	= Readonly<{
	screen:	fbModel.ScreenOfHotel,
	answer:	fbModel.AnswerOfHotel,
}>;

export const Hotel = ({ screen, answer }:HotelProps):JSX.Element => {
	const setCounter = (val:number):void => {
		actions.doRefresh(
			actions.answerContent(screen.id).cast<fbModel.AHotel>().atKey("counter").atKey("counterValue").set(val)
		);
	};

	const setRadio = (wasChecked:boolean, valueRadio:string, radioItemId:fbModel.RadioItemId):void => {
		actions.doRefresh(
			actions.answerContent(screen.id).cast<fbModel.AHotel>().mod(it =>
				({
					...it,
					radioItemId:	wasChecked? null : radioItemId,
					valueRadio:		wasChecked? null : valueRadio,
					mailRadioValue:	!wasChecked && valueRadio !== ""
									?	screen.content.radioGroup.items.find(it => it.valueRadio === valueRadio)?.label ?? ""
									:	"",
				})
			)
		);
	};

	// shortened screen by removing
	// <div class="Hotel-title heading">{screen.content.title}</div>
	return (
		<div class="Hotel Screen">
			<Counter
				counterValue={answer.content.counter.counterValue}
				countItem={screen.content.counter}
				action={setCounter}
			/>
			<div class="heading">{screen.content.radioGroup.title}</div>
			<div class={`RadioGroup ${screen.content.radioGroup.layout}`}>
				{
					screen.content.radioGroup.items.map((item) =>
						<RadioButton
							key={item.radioItemId}
							checked={answer.content.valueRadio}
							item={item}
							action={setRadio}
						/>
					)
				}
			</div>
		</div>
	);
};
