import { JSX }	from "preact";

export type ProgressIndicatorProps	= Readonly<{
	// between 0 and 1 (inclusive)
	progress:	number,
}>;

export const ProgressIndicator = ({ progress }:ProgressIndicatorProps):JSX.Element => {
	const divisor	= progress * 100;
	const gradient	= `radial-gradient(var(--coreColor) 50%, transparent 55%), conic-gradient(var(--processDoneColor) 0% ${divisor}%, var(--processOpenColor) ${divisor}% 100%)`;

	return (
		<div
			class="ProgressIndicator"
			style={ { background: gradient } }
		>
			{/* percentage */}
		</div>
	);
};
