import { JSX }	from "preact";

import { Fn }			from "ts-base/fn";

import * as i18n		from "@geotoura/shared/i18n";

import { useMessages }	from "@geotoura/fb/useMessages";
import { ScreenMode }	from "@geotoura/fb/model";
import * as actions		from "@geotoura/fb/actions";

export type NavProps	= Readonly<{
	// this only exists to pass it to the server in the post of the result
	languageCode:	i18n.LanguageCode,
	screenMode:		ScreenMode,
	formIsValid:	boolean,
}>;

export const Nav = ({ languageCode, screenMode, formIsValid }:NavProps):JSX.Element => {
	const msg = useMessages();

	return	(
		<div class="Nav">
			<div class={`Nav-Buttons ${screenMode === "thanks" || screenMode === "first" ? "single" : ""}`}>
				{
					(screenMode === "other" || screenMode === "send") &&
					<button onClick={() => actions.goBack()} type="button" class="Nav-Button Nav-Back">{msg.nav.back}</button>
				}
				{
					screenMode === "send"
					?	<button onClick={() => actions.postQuestionnaire(languageCode)} type="submit" class={`Nav-Button Nav-Continue ${!formIsValid ? "disabled" : ""}`}>
							{msg.nav.send}
						</button>
					:	screenMode === "thanks"
					?	<button onClick={() => actions.close()} type="submit" class="Nav-Button Nav-Continue">
							{msg.nav.finish}
						</button>
					:	<button onClick={() => actions.goNext()} type="submit" class="Nav-Button Nav-Continue">
							{
								screenMode === "first"	? msg.nav.start
							:	screenMode === "other"	? msg.nav.continue
							:	Fn.impossible(screenMode)
						}
					</button>
				}
			</div>
		</div>
	);
};
